import React from 'react';

// Own components
import SEO from '../components/seo';
import Layout from '../components/Layout/en';
import { Multimedia } from '../containers/Multimedia';

const MultimediaPage = () => (
  <Layout>
    <SEO title="Multimedia" />
    <Multimedia />
  </Layout>
);

export default MultimediaPage;
